import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import theme from '../../utils/tailwindcss';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { CurrentUser } from 'src/app/core/models/CurrentUser.model';

@Component({
    selector: 'vex-toolbar-user',
    templateUrl: './toolbar-user.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;
  icPerson = icPerson;

  theme = theme;
  currentUser :CurrentUser;
  constructor(private popover: PopoverService,
    private cd: ChangeDetectorRef, public authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.setDecodeToken()

    this.currentUser  = this.authService.tokenDecoded;

  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

}
