import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../popover/popover-ref';
import { AuthenticationService } from '../../../../app/core/authentication/authentication.service';
import { CurrentUser } from 'src/app/core/models/CurrentUser.model';
import { ChatAppSignalRService } from 'src/app/core/signalr/chat-app-signal-r.service';
import { MatDialog } from '@angular/material';
import { ToolbarUserChangePasswordComponent } from '../toolbar-user-change-password/toolbar-user-change-password.component';

export interface OnlineStatus {
    id: 'online' | 'away' | 'dnd' | 'offline';
    label: string;
    icon: Icon;
    colorClass: string;
}

@Component({
    selector: 'vex-toolbar-user-dropdown',
    templateUrl: './toolbar-user-dropdown.component.html',
    styleUrls: ['./toolbar-user-dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

    items: MenuItem[] = [
        //   {
        //     id: '1',
        //     icon: icAccountCircle,
        //     label: 'My Profile',
        //     description: 'Personal Information',
        //     colorClass: 'text-teal-500',
        //     route: '/pages/profile'
        //   },
        //   {
        //     id: '2',
        //     icon: icMoveToInbox,
        //     label: 'My Inbox',
        //     description: 'Messages & Latest News',
        //     colorClass: 'text-primary-500',
        //     route: '/apps/chat'
        //   },
        //   {
        //     id: '3',
        //     icon: icListAlt,
        //     label: 'My Projects',
        //     description: 'Tasks & Active Projects',
        //     colorClass: 'text-amber-500',
        //     route: '/apps/scrumboard'
        //   },
        //   {
        //     id: '4',
        //     icon: icTableChart,
        //     label: 'Billing Information',
        //     description: 'Pricing & Current Plan',
        //     colorClass: 'text-purple-500',
        //     route: '/pages/pricing'
        //   }
    ];


    statuses: OnlineStatus[] = [
        {
            id: 'online',
            label: 'Activo',
            icon: icCheckCircle,
            colorClass: 'text-green-500'
        },
        {
            id: 'away',
            label: 'Desconectado',
            icon: icAccessTime,
            colorClass: 'text-orange-500'
        },
        {
            id: 'dnd',
            label: 'No molestar',
            icon: icDoNotDisturb,
            colorClass: 'text-red-500'
        },
        // {
        //     id: 'offline',
        //     label: 'Fuera de línea',
        //     icon: icOfflineBolt,
        //     colorClass: 'text-gray-500'
        // }
    ];

    activeStatus: OnlineStatus = this.statuses[0];

    trackById = trackById;
    icPerson = icPerson;
    icSettings = icSettings;
    icChevronRight = icChevronRight;
    icArrowDropDown = icArrowDropDown;
    icBusiness = icBusiness;
    icVerifiedUser = icVerifiedUser;
    icLock = icLock;
    icNotificationsOff = icNotificationsOff;

    currentUser: CurrentUser;
    constructor(private cd: ChangeDetectorRef,
        public authService: AuthenticationService,
        public dialog: MatDialog,
        private chatSignalR: ChatAppSignalRService,
        private popoverRef: PopoverRef<ToolbarUserDropdownComponent>) { }

    ngOnInit() {

        this.authService.setDecodeToken()
        this.currentUser = this.authService.tokenDecoded;
    }


    setStatus(status: OnlineStatus) {
        this.activeStatus = status;
        this.cd.markForCheck();
    }

    get token() {
        return this.authService.tokenDecoded;
    }

    close() {
        this.popoverRef.close();
    }


    logout() {
        this.chatSignalR.ExitGroup();
        this.chatSignalR.disconect();
        this.popoverRef.close();
        this.authService.logout();
    }

    openModalChangePassword() {
        this.dialog.open(ToolbarUserChangePasswordComponent, {
            data: {}, disableClose: true
        });
    }


}
