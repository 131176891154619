import { Injectable, EventEmitter, Inject } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { ChatMessage } from 'src/app/pages/apps/chat/chat.component';
@Injectable({
  providedIn: 'root'
})
export class ChatAppSignalRService {


  private hubConnection: signalR.HubConnection
  mensajeRecibido = new EventEmitter<ChatMessage>();
  private baseUrl: string;

  private grupoChatUsuario;
  public usuarioid;

  constructor(
    @Inject('BASE_URL') _baseUrl: string,
  ) {
    this.baseUrl = _baseUrl;
  }


  public disconect() {
    this.hubConnection.stop().catch(err => {
      console.error('Error while stopping connection: ' + err);
      // this.mostrarAlertaMensaje("Error conexión al chat.")
    })
  }

  public startConnection = () => {
    this.grupoChatUsuario = "usuario_id_" + this.usuarioid
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.baseUrl + "chatapp")
      .build();
    this.hubConnection
      .start().
      then(ok => {
        this.subscribirMetodosChatApp();
        this.mostrarAlertaMensaje("Conexión a chat establecida.")
        this.JoinGroup(this.grupoChatUsuario)
      })
      .catch(err => {
        console.log('Error while starting connection: ' + err);
        this.mostrarAlertaMensaje("Error conexión al chat.")
        setTimeout(() => {
          this.startConnection();
        }, 2000)

      });
  }

  subscribirMetodosChatApp() {
    this.hubConnection.on('EnviarMensaje', (mensaje) => {
      // this.data = data;
      // this.mostrarAlertaMensaje("Mensaje recibido.");
      this.mensajeRecibido.emit(mensaje);
    });
  }

  public EnviarMensaje(usuarioID: number, mensaje: any) {
    let usuarioGrupo = "usuario_id_" + usuarioID;

    this.hubConnection.invoke("EnviarMensaje", usuarioGrupo, mensaje).catch(err => {
      this.mostrarAlertaMensaje("Error, no se pudo enviar el mensaje.")
      return console.error(err);
    });

  }


  private JoinGroup(group: string) {
    this.hubConnection.invoke("JoinGroup", group).catch(err => {
      this.mostrarAlertaMensaje("Error, no se pudo unir al chat.")
      return console.error('JoinGroup Error: ' + err);
    });
  }

  public ExitGroup() {
    this.hubConnection.invoke("ExitGroup", this.grupoChatUsuario).catch(err => {
      return console.error('ExitGroup error: ' + err);
    });
  }


  mostrarAlertaMensaje(message: string) {
    // this._snackBar.open(message, null, {
    //   duration: 1000,
    // });
    console.log(message)

  }

  playAudio() {
    let audio = new Audio();
    audio.src = "assets/audio/whatsapp-apple.mp3";
    audio.load();
    audio.play();
  }

}
