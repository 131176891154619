import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { DataApi } from '../enums/data-api';
import { UsuarioForLogin } from 'src/app/pages/pages/auth/login/UsuarioForLogin';
import { ApiService } from '../backend/api.service';
import { CurrentUser } from '../models/CurrentUser.model';
import { ComboBox } from '../models/Api/ComboBox.model';
import { NgxPermissionsService } from 'ngx-permissions';
import * as Rx from "rxjs";
import { StateService } from 'src/app/pages/modulos/captura/state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  helper = new JwtHelperService();
  tokenDecoded: CurrentUser;


  private currentUserPermisionsSubject$ = new Rx.BehaviorSubject<string[]>([]);
  public currentUserPermisions: Rx.Observable<string[]>;



  constructor(
    public httpService: ApiService,
    public permissionsService: NgxPermissionsService,
    private router: Router,
    private paramsService: StateService, 
    // private toastService: ToastService,
  ) { }


  login(usuario: UsuarioForLogin) {
    return this.httpService.DoPostAny<UsuarioForLogin>(DataApi.Authentication,
      "Login", usuario)
      .pipe(
        map(res => {
          // login successful if there's a jwt token in the response  && user.token
          if (res.ok) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes

            let token = res.valores[0];
            localStorage.setItem("keyViaB", token);
            this.setDecodeToken();

            let permisosModel: Permisos[] = res.valores[1];
            let permisos: string[] = [];

            permisosModel.forEach(x => {
              permisos.push(x.nombre);
            })
            // console.log(permisos)

            localStorage.setItem("p", JSON.stringify(permisos));
            // this.currentUserPermisionsSubject = new Rx.BehaviorSubject<Permisos[]>(permisos);
            this.currentUserPermisionsSubject$.next(permisos);
            this.currentUserPermisions = this.currentUserPermisionsSubject$.asObservable();
            // console.table(permisos.map(p => p.nombre));
            // this.permissionsService.loadPermissions(permisos.map(p => p.nombre));
            
            localStorage.setItem("RolEstadoPresupuestoId", res.valores[2]);

          }
          return res;
        }));

  }


  getRolEstadoPresupuestoId() {
    return localStorage.getItem("RolEstadoPresupuestoId");
  }
  
  findPermisos() {
    if (localStorage.getItem("p")) {
      this.currentUserPermisionsSubject$.next(JSON.parse(localStorage.getItem("p")));
      this.currentUserPermisions = this.currentUserPermisionsSubject$.asObservable();
    }
  }

  public get currentUserPermisionsValue(): string[] {
    return this.currentUserPermisionsSubject$.value;
  }



  getCompanias() {
    return this.httpService.DoPostAny<ComboBox>(DataApi.Authentication,
      "GetCompanias", null);
  }


  loggedIn(): boolean {
    const token = localStorage.getItem("keyViaB");
    return !this.helper.isTokenExpired(token);
  }
  logout() {
    localStorage.removeItem('keyViaB');
    this.router.navigateByUrl('/login');
    this.paramsService.data = null;
    this.paramsService.cuenta = null;
  }

  setDecodeToken(): void {
    let token = localStorage.getItem("keyViaB");
    this.tokenDecoded = this.helper.decodeToken(token);
  }

}

export class Permisos {
  id: number;
  nombre: string;
}
