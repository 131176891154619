import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
// import { ToastrModule } from 'ngx-toastr';   
import { MatSnackBarModule } from '@angular/material';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    // ToastrModule.forRoot(), // ToastrModule added
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [getHost(),'lacortina.ddns.net'],
        blacklistedRoutes: [getHost() + '/api/Authentication']
      }
    }),
    // Vex
    VexModule,
    CustomLayoutModule
  ],

  providers: [{ provide: 'BASE_URL', useFactory: getBaseUrl },
  { provide: LocationStrategy, useClass: HashLocationStrategy }],

  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return localStorage.getItem("token");
}

export function getBaseUrl() {
  return environment.apiUrl;
}

export function getHost() {
    return window.location.host;
}






