export enum DataApi {
  Usuario = 1,
  Authentication = 2,
  ComboBox = 3,
  Presupuesto_PlanificadorCompras = 4,
  Presupuesto_Captura = 5,
  ComboBox_Presupuesto = 6,
  Template_Presupuesto = 7,
  GastosBasicos_Presupuesto = 8,
  Reporte_Presupuesto = 9,
  Mantenimiento_Presupuesto_Productos = 10,
  ActivoFijos_Presupuesto = 11,
  Mantenimiento_Presupuesto_Empleados = 12,
  Mantenimiento_Precios_Taller_Respuesto = 13,
  Mantenimiento_Conceptos = 14,
  Mantenimiento_Presupuesto_Empleado = 15,
  Mantenimiento_Presupuesto_Marca = 16,
  Mantenimiento_Presupuesto_Sucursal = 17,
  Mantenimiento_Presupuesto_Canal = 18,
  Mantenimiento_Presupuesto_TipoEmpleado = 19,
  Mantenimiento_Presupuesto_Jornada = 20,
  Mantenimiento_Presupuesto_ArticulosActivosFijos = 21,
  Mantenimiento_Presupuesto_MarcaProcedencia = 22,
  Mantenimiento_Presupuesto_Anio = 23,
  Mantenimiento_Presupuesto_Area = 24,
  Mantenimiento_Presupuesto_EstadoPresupuesto = 25,
  Mantenimiento_Presupuesto_Categoria = 26,
  Mantenimiento_Presupuesto_Negocio = 27,
  Mantenimiento_Presupuesto_moneda = 28,
  Mantenimiento_Presupuesto_tipocombustible = 29,
  Mantenimiento_Presupuesto_UsuarioSucursalEnroll = 30,
  Mantenimiento_Presupuesto_combustible = 31,

  ChatApp = 50,
  CalculoComisiones = 51,
  CalculoGeneral = 52,

  Mantenimiento_Presupuesto_IndicadoresComision = 92,
  Mantenimiento_Presupuesto_Puesto = 93,
  Mantenimiento_Presupuesto_TipoIndicadoresComision = 94,
  Mantenimiento_Presupuesto_TipoPagoComision = 95,
  Mantenimiento_Presupuesto_CategoriaActivoFijo = 96,
  Mantenimiento_Presupuesto_CategoriaCuenta = 97,
  Mantenimiento_Presupuesto_cuentaEnrrolActivo = 98,
  Mantenimiento_Presupuesto_RolEstadoPresupuesto = 99,
  Mantenimiento_Presupuesto_EjecutadoStock = 100,
  Mantenimiento_Presupuesto_EjecutadoVenta = 101,
  Mantenimiento_Presupuesto_TasaMoneda = 102,

  Mantenimiento_Concepto_PresupuestoCategoria = 130,
  Mantenimiento_Departamento = 131,
  Mantenimiento_Departamento_PresupuestoNegocioEnrrol = 132,
  Mantenimiento_Departamento_UsuarioAutorizacionPresupuestoEnrroll = 133,
  Mantenimiento_Departamento_UsuarioPresupuestoEnrroll = 134,
  Mantenimiento_Negocio_Presupuesto_CanalEnrrol = 135,
  Mantenimiento_Negocio_Presupuesto_MarcaEnrrol = 136,
  Mantenimiento_Negocio_Presupuesto_CuentaEnrrol = 137,
  Mantenimiento_Articulo_NegocioEnrrol = 138,
  Mantenimiento_Marca_Aplica_Pago_Rundownd = 139,
  Mantenimiento_Cuentas_Contable_CalculoEnrrol = 140,
  Mantenimiento_Rol = 141,
  Mantenimiento_Rol_EstadoPresupuesto = 142,
  Mantenimiento_Rol_PermisoEnrroll = 143,
  Mantenimiento_UsuarioCanalEnroll = 144,
  Mantenimiento_UsuarioMarcaEnroll = 145,
  Mantenimiento_UsuarioPermisosEnroll = 146,
  Mantenimiento_Concepto = 147,
  Mantenimiento_Presupuesto_Articulo = 148,
  Mantenimiento_Presupuesto_Modelo = 149,
  Mantenimiento_Presupuesto_CuentaContable = 150,
  Mantenimiento_Presupuesto_PuestoComision = 151,
  Mantenimiento_Linea_Estado = 152,
  Parametro = 153,
  ParametroConstante = 154,
  Mantenimiento_ClasificacionCuenta_TipoArticulo = 155,
  Flujo_Vehiculo_Solicitud = 156,
  Mantenimiento_Marca_GerenteEnrrol = 157
}

export const dataApiRootMap: { [api: string]: string } = {
  "1": "api/Usuario",
  "2": "api/Authentication",
  "3": "api/ComboBox",
  "4": "api/PlanificadorCompra",
  "5": "api/CapturaPresupuesto",
  "6": "api/ComboBoxPresupuesto",
  "7": "api/TemplatePresupuesto",
  "8": "api/GastosBasicos",
  "9": "api/ReportePresupuesto",
  "10": "api/MantenimientoPresupuestoProducto",
  "11": "api/ActivosFijos",
  "12": "api/MantenimientoPresupuestoEmpleados",
  "13": "api/MantenimientoPreciosTallerRespuesto",
  "14": "api/MantenimientoPresupuestoConcepto",
  "15": "api/Empleado",
  "16": "api/Marca",
  "17": "api/Sucursal",
  "18": "api/Canal",
  "19": "api/TipoEmpleado",
  "20": "api/Jornada",
  "21": "api/ArticuloActivoFijos",
  "22": "api/MarcaProcedencia",
  "23": "api/Anio",
  "24": "api/Area",
  "25": "api/EstadoPresupuesto",
  "26": "api/Categoria",
  "27": "api/Negocio",
  "28": "api/Moneda",
  "29": "api/TipoCombustible",
  "30": "api/UsuarioSucursalEnroll",
  "31": "api/Combustible",

  "50": "api/ChatApp",
  "52": "api/CalculoGeneral",

  "92": "api/IndicadoresComision",
  "93": "api/Puesto",
  "94": "api/TipoIndicadoresComision",
  "95": "api/TipoPagoComision",
  "96": "api/CategoriaActivoFijo",
  "97": "api/CategoriaCuenta",
  "98": "api/CuentaContableEnrrolActivo",
  "99": "api/RolEstadoPresupuesto",
  "100": "api/EjecutadoStock",
  "101": "api/EjecutadVenta",
  "102": "api/TasaMoneda",

  "130": "api/ConceptoPresupuestoCategoria",
  "131": "api/Departamento",
  "132": "api/DepartamentosPresupuestoNegocioEnrrol",
  "133": "api/DepartamentoUsuarioAutorizacionPresupuestoEnrroll",
  "134": "api/DepartamentoUsuarioPresupuestoEnrroll",
  "135": "api/NegocioPresupuestoCanalEnrrol",
  "136": "api/NegocioPresupuestoMarcaEnrrol",
  "137": "api/NegocioPresupuestoCuentaEnrrol",
  "138": "api/ArticuloNegocioEnrrol",
  "139": "api/MarcaAplicaPagoRundownd",
  "140": "api/CuentasContableCalculoEnrrol",
  "141": "api/Rol",
  "142": "api/RolEstadoPresupuesto",
  "143": "api/RolPermisoEnroll",
  "144": "api/UsuarioCanalEnroll",
  "145": "api/UsuarioMarcaEnroll",
  "146": "api/UsuarioPermisosEnroll",
  "147": "api/Concepto",
  "148": "api/Articulo",
  "149": "api/Modelo",
  "150": "api/CuentaContable",
  "151": "api/PuestoComision",
  "152": "api/LineaEstado",
  "153": "api/Parametro",
  "154": "api/ParametroConstante",
  "155": "api/ClasificacionCuentaTipoArticulo",
  "156": "api/FlujoVehiculo",
  "157": "api/MarcaGerenteEnrroll",
};
