import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { ApiService } from 'src/app/core/backend/api.service';
import { DataApi } from 'src/app/core/enums/data-api';
import { stagger60ms } from 'src/@vex/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { ToolbarUserDropdownComponent } from '../toolbar-user-dropdown/toolbar-user-dropdown.component';
@Component({
  selector: 'vex-toolbar-user-change-password',
  templateUrl: './toolbar-user-change-password.component.html',
  styleUrls: ['./toolbar-user-change-password.component.scss'],
  animations: [
    stagger60ms,
    fadeInUp400ms
  ]
})
export class ToolbarUserChangePasswordComponent implements OnInit {

  Formulario: FormGroup;
  submitted = false;

  loadingBtn = false;
  loading = false;

  constructor(private apiService: ApiService,
    private _snackBar: MatSnackBar,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ToolbarUserDropdownComponent>,
    @Inject(MAT_DIALOG_DATA) public dataForm: any) { }

  ngOnInit() {
    this.CreateForm();

  }


  private CreateForm() {

    this.Formulario = this.formBuilder.group({
      usuario: [null,],
      passActual: [null, [Validators.required]],
      passNueva: [null, [Validators.required]],
      passNuevaConfirmacion: [null, [Validators.required]],
    });

  }

  get f() { return this.Formulario.controls; } // acceder a los controles del formulario para no escribir tanto codigo en el html


  onSubmit() {
    let passNueva = this.f.passNueva.value;
    let passNuevaConfirmacion = this.f.passNuevaConfirmacion.value;

    if (passNueva != passNuevaConfirmacion) {
      this.MensajeRespuesta("Las contraseñas no son iguales, confirme de nuevo.")
      return;
    }

    this.f.usuario.setValue(this.authService.tokenDecoded.unique_name);

    this.submitted = true;
    if (this.Formulario.invalid) {
      return;
    }
    this.guardar();
  }

  guardar() {

    this.loadingBtn = true;
    this.apiService.DoPostAny<any>(DataApi.Authentication, "ChangePassword", this.Formulario.value).subscribe(r => {

      if (!r.ok) {
        console.error(r.errores[0]);
        this.MensajeRespuesta("Error: " + r.errores[0]);
      } else {
        this.MensajeRespuesta("Operación realizada.");
        this.dialogRef.close();
      }
      this.loadingBtn = false;
    }, error => {
      this.loadingBtn = false;
      console.error(error);
    });

  }


  close() {
    this.dialogRef.close();
  }

  MensajeRespuesta(message: string) {
    this._snackBar.open(message, null, {
      duration: 1000,
    });
  }

}
