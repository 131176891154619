
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from './../models/Api/response.model';
import { Request } from './../models/Api/request.model';
import { Paginacion } from '../models/Api/paginacion.model';
import { DataApi, dataApiRootMap } from '../enums/data-api';
import { Injectable, Inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApiService {


    http: HttpClient;
    baseUrl: string;

    constructor(_http: HttpClient, @Inject('BASE_URL') _baseUrl: string) {
        this.http = _http;
        this.baseUrl = _baseUrl;
    }

    public GetAllWithPagination<T>(api: DataApi, Method: string, Columna: string, PaginaNo: number = 1, PaginaSize: number = 10, OrderASC: boolean = true, parametros: any = {}): Observable<Response<T>> {
        let request = new Request<T>();
        request.parametros = parametros;
        request.pagina = new Paginacion();
        request.pagina.paginaNo = PaginaNo;
        request.pagina.paginaSize = PaginaSize;
        request.pagina.ordenAsc = OrderASC;
        request.pagina.ordenColumna = Columna;
        return this.http.post<Response<T>>(this.baseUrl + dataApiRootMap[api] + "/" + Method, request);
    }

    public DoPost<T>(api: DataApi, Method: string, parametros: any): Observable<Response<T>> {
        let request = new Request<T>();
        request.parametros = parametros;
        return this.http.post<Response<T>>(this.baseUrl + dataApiRootMap[api] + "/" + Method, request);
    }


    public DoPostAny<T>(api: DataApi, Method: string, request: any): Observable<Response<T>> {
        return this.http.post<Response<T>>(this.baseUrl + dataApiRootMap[api] + "/" + Method, request);
    }


    public DoPostSmartWebService(servicio: string, metodo: string, request: any): Observable<any> {
        // const proxyurl = "https://grupoviamar.com/myapp/";
        // const url = `lacortina.ddns.net/wscontacto2/${servicio}.asmx`; 
        const proxyurl ="";
        const url = `https://lacortina.ddns.net/wscontacto/${servicio}.asmx`; 

        const headers = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
        return this.http.post(proxyurl + url + "/" + metodo, JSON.stringify(request), headers);
    }


}
