import { Injectable } from '@angular/core';
import { TemplateParamsViewModel } from './model/TemplateParamsViewModel';

@Injectable({
    providedIn: 'root'
})
export class StateService {

    public data: TemplateParamsViewModel
    public cuenta: any

    constructor() { }
}
